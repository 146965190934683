import React, { useEffect } from "react";
import {
  PointTitle,
  Attribute,
  Carousel,
  Tiles,
  PromotionCard,
  SquareButton
} from "./components";

import { trackpage } from "./trackpage";
import { Link } from "react-router-dom";
import { abctest33 } from "./ABtesting";

const abbutton = abctest33(
  {title: "Find out more", link: "/how"}, 
  {title: "Take the next step", link: "/how"}, 
  {title: "Learn more", link: "/how"}
);

function About() {
  useEffect(() => {
    document.title = `About`;
    trackpage({
      page: window.location.pathname,
      search: window.location.search.substring(1)
    });
  });
  return (
    <div>
      <Carousel>
        <div style={{gridArea: 'tiles', margin: '0 20px' }}>
          <PointTitle style={{ marginBottom:'10px' }} align={'center'}>
            What We Stand For
          </PointTitle>
          <div style={{ fontSize: "16px", margin: "20px 0", color: "#9499A3", fontFamily: "sans-serif", textAlign: 'left'}}>
            { "We draw inspiration from the best techniques in coaching, memory, and breakthroughs. We have a philosophy of continuous improvement and we're obsessed about how we can help you achieve your life goals."}
          </div>
          <Attribute style={{fontSize: '24px', textAlign: 'center'}}>We find opportunities to leverage technology to restore and respect the human side of life.</Attribute>
        </div>
      </Carousel>
      <PointTitle align={'center'} style={{ marginBottom:'10px' }}>
        Our Values
      </PointTitle>
      <Carousel>
        <Tiles>
          {/* <PromotionCard name={'Focus on the right goals at the right time'} text={'You know which goals to focus on for your next step. Connect your purpose to outcomes.'}/>
          <PromotionCard name={'Get unblocked and keep on track'} text={'Nudges to get unblocked. You’ll be back on track before you know it.'}/>
          <PromotionCard name={'Target insights that matter to you most'} text={'Collect insights from people and keep your own insights to help you achieve any goal.'}/> */}
          <PromotionCard name={'Grow.'} text={'Growth is how you strengthen what you already have to create more value in your life.'} imageheight={'90px'} icon={'wreath'}/>
          <PromotionCard name={'Learn.'} text={'Learning is how you find something new and put it into action to impact your life.'} imageheight={'110px'} icon={'wreath'}/>
          <PromotionCard name={'Evolve.'} text={'Evolution is how you transform and step into the next phase of your vision.'} imageheight={'110px'} icon={'wreath'}/>
        </Tiles>
      </Carousel>
      <PointTitle align={'center'} style={{ marginBottom:'10px' }}>
        What we're NOT about
      </PointTitle>
      <Carousel>
        <Tiles>
          {/* <PromotionCard name={'Focus on the right goals at the right time'} text={'You know which goals to focus on for your next step. Connect your purpose to outcomes.'}/>
          <PromotionCard name={'Get unblocked and keep on track'} text={'Nudges to get unblocked. You’ll be back on track before you know it.'}/>
          <PromotionCard name={'Target insights that matter to you most'} text={'Collect insights from people and keep your own insights to help you achieve any goal.'}/> */}
          <PromotionCard name={'Not about productivity'} text={'Productivity alone is not going to get you to the next level. We believe breakthroughs come from attention and evolution.'} imageheight={'90px'}/>
          <PromotionCard name={'Workflows are lame'} text={'While we track actions and tasks, we think focusing too much on workflows is distracting from the big picture.'} imageheight={'110px'}/>
          <PromotionCard name={'No grasping'} text={'We have been taught and trained to grasp for the next thing. We help you harness your attention to maintain a stable focus.'} imageheight={'110px'}/>
        </Tiles>
      </Carousel>
      <PointTitle align={'center'} style={{ marginBottom:'10px', textAlign: 'center' }}>
        How does it work?
      </PointTitle>
      <div style={{ fontSize: "20px", margin: "20px 0", color: "#9499A3", fontFamily: "sans-serif", textAlign: "center" }}>Click below to find out.</div>
      <div style={{ display: "flex", justifyContent: "center", marginTop: "10px", marginBottom: "50px" }}>
        <Link to={'/get-started'}>
          <SquareButton
            float={"none"}
            margin={10}
            light={true}
          >
            {"Get started"}
          </SquareButton>
        </Link>
        <Link to={abbutton.link}>
          <SquareButton
            float={"none"}
            margin={10}
          >
            {abbutton.title}
          </SquareButton>
        </Link>
      </div>
    </div>
  );
}

export default About;
