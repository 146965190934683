import React from "react";
import styled, { keyframes, css } from "styled-components";

import { ApolloProvider } from "react-apollo";
import { Query } from "react-apollo";

import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
//import fetch from "node-fetch";
import gql from "graphql-tag";
import { SquareButton, WheelImage } from "./components";
import { trackpage } from "./trackpage";

var endpoint = `${process.env.REACT_APP_GRAPHQL}`; //environment variable for graphql endpoint
if (endpoint === "undefined") endpoint = "/server"; //for test or dev environment.

const cache = new InMemoryCache();
const link = new HttpLink({
  uri: endpoint,
  fetch: fetch
});

const client = new ApolloClient({
  cache,
  link
});

const QUERY_WHEELS = gql`
  query publicwheels($tag: String) {
    publicwheels(tag: $tag) {
      _id
      startarea {
        _id
        name
        definition
        areas {
          _id
          name
          definition
        }
      }
    }
  }
`;

export const WheelCarousel = tag => {
  return (
    <div
      style={{
        height: "500px",
        clear: "both",
        overflowX: "scroll",
        width: "100%"
      }}
    >
      <ApolloProvider client={client}>
        <div>
          <Query
            query={QUERY_WHEELS}
            variables={tag}
            fetchPolicy="network-only"
            /* pollInterval={5000} */
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <div style={{}}></div>;
              }

              if (error) {
                return <div style={{}}></div>;
              }

              return (
                <Carousel>
                  <div
                    style={{
                      display: "flex",
                      margin: "0 10px",
                      textAlign: "center"
                    }}
                  >
                    {data.publicwheels
                      ? data.publicwheels.map(wheel => {
                          return (
                            <a
                              style={{ textDecoration: "none" }}
                              href="https://www.slicegoal.com/app/?page=signup"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <div
                                onClick={() =>
                                  trackpage({
                                    page: window.location.pathname,
                                    search: window.location.search.substring(1),
                                    action: "clickwheel",
                                    actioninfo: wheel.startarea.name,
                                    abconfig: "carousel=classic"
                                  })
                                }
                                key={wheel._id}
                                value={wheel.name}
                                style={{
                                  cursor: "pointer",
                                  padding: "1px",
                                  borderRadius: "5px",
                                  width: "230px",
                                  height: "330px",
                                  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                  margin: "0 10px",
                                  position: "relative"
                                }}
                              >
                                <div style={{ marginTop: "30px" }} />
                                  <TemplateWheel
                                    area={wheel.startarea}
                                    display={"template"}
                                    view={"global"}
                                  />
                              </div>
                            </a>
                          );
                        })
                      : ""}
                  </div>
                </Carousel>
              );
            }}
          </Query>
        </div>
      </ApolloProvider>
    </div>
  );
};

export const TemplateWheel = ({ area, display }) => {
  return (
    <>
      <div style={{ width: "300", height: "200", marginTop: "30px", position: "relative" }}>
        <WheelTitle>{area ? area.name : "no title"}</WheelTitle>
        {area.definition? <WheelDefinition>{area.definition}</WheelDefinition> : <div style={{height:'11.5px'}}/>}
        <div style={{margin: "-10px auto"}}><WheelImage /></div>
        <div id={"areas"} style={{ color: "black" }}>
          {area
            ? area.areas.length > 0
              ? area.areas.map((wedgearea, count) => {
                  return <div style={{ fontSize: "12px" }}>{wedgearea.name}</div>;
                })
              : null
            : null}
        </div>
      </div>
      <div style={{position: 'absolute', bottom: 0, marginLeft: 'auto', width: "100%"}}>
        <SquareButton
          float={"none"}
          margin={10}
          light={true}
        >
          Choose me
        </SquareButton>
      </div>
    </>
  );
};

const typography = {
  heading: "Source Sans Pro",
  subheading: "Source Sans Pro",
  body: "Source Sans Pro",
  sizeheading: "1.2em", //Bases: 1em, ratio: 1.2 https://www.modularscale.com/
  sizesubheading: "12px",
  sizebody: "0.9em",
  sizemini: "0.694em",
  lineheight: "1.5em",
  mobile: {
    sizeheading: "1.2em",
    sizesubheading: "2.0em",
    sizebody: "1.5em",
    sizemini: "0.694em"
  },
  weight: { normal: 500, bold: 700 }
};

const fadeinkeys = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeinfast = css`
  opacity: 1;
  animation: ${fadeinkeys} 0.5s;
`;

const Carousel = styled.div`
  margintop: 20px;
  padding: 20px;
  display: flex;
  alignitems: center;
  ${fadeinfast}
`;

const WheelTitle = styled.div`
  font-family: ${typography.heading};
  text-align: center;
  margin-top: 20px;
  font-size: ${typography.sizeheading};
  @media (max-width: 400px) {
    font-size: ${typography.mobile.sizeheading};
  }
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  opacity: 1;
  ${fadeinfast}
  cursor: pointer;
  color: black;
`;

const WheelDefinition = styled.div`
  color: black;
  font-style: Normal;
  text-align: center;
  font-size: 10px;
  @media (max-width: 400px) {
    font-size: 10px;
  }
  margin: 5px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  opacity: 1;
  dominantbaseline: auto;
`;
