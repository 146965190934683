import React, { useEffect } from "react";
import {
  Feature,
  BodyDiv,
  ProductShot,
  LeftGrid,
  PointTitle,
  Intro,
  Features,
  SquareButton
} from "./components";
import { Link } from "react-router-dom";

import { WheelCarousel } from "./wheel";

import { trackpage } from "./trackpage";

function Coach() {
  useEffect(() => {
    document.title = `1on1 Coaching`;
    trackpage({
      page: window.location.pathname,
      search: window.location.search.substring(1)
    });
  });
  return (
    <BodyDiv style={{ textAlign: "center" }}>
      <title>SliceGoal Client Coaching</title>
      <Intro>
        <div style={{ margin: "0 20px" }}>
          <PointTitle style={{ marginLeft: "0px" }}>
            Coach people with SliceGoal
          </PointTitle>
        </div>
      </Intro>
      <LeftGrid>
        <div style={{ gridArea: "left", margin: "auto 0" }}>
          <div
            style={{
              color: "#86E0F8",
              fontSize: "20px",
              margin: "20px",
              textAlign: "left"
            }}
          >
            {"Coaching that guides people's success"}
          </div>
          <Features>
            <Feature feature={"Visualise goals to improve results"} />
            <Feature feature={"Track progress to increase engagement"} />
            <Feature
              feature={"Highlight growth areas to coach like an rockstar"}
            />
          </Features>
        </div>
        <div
          style={{
            gridArea: "right"
          }}
        >
          <ProductShot />
        </div>
      </LeftGrid>
      {/* <BottomCTA>
        <a target="_blank" href="https://www.slicegoal.com/app/?page=signup&account=coach">
          <Button float={"none"}>Start your free Team Coaching Wheel</Button>
        </a>
      </BottomCTA> */}
      <WheelCarousel tag="life" />
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}
      >
        <Link to={"/teams"}>
          <SquareButton float={"none"} margin={10}>
            Teams
          </SquareButton>
        </Link>
        <Link to={"/self-coaching"}>
          <SquareButton float={"none"} margin={10}>
            Self Coaching
          </SquareButton>
        </Link>
      </div>
    </BodyDiv>
  );
}

export default Coach;
