import React, { useEffect } from "react";
import {
  PointTitle,
  BodyDiv,
  SquareButton,
  Intro,
  LeftGrid,
  Features,
  Feature,
  ProductShot
} from "./components";
import { Link } from "react-router-dom";

import { WheelCarousel } from "./wheel";
import { trackpage } from "./trackpage";

function Self() {
  useEffect(() => {
    document.title = `Self-Coaching`;
    trackpage({
      page: window.location.pathname,
      search: window.location.search.substring(1)
    });
  });

  return (
    <BodyDiv style={{ textAlign: "center" }}>
      <title>SliceGoal Self-coaching</title>
      <Intro>
        <div style={{ margin: "0 20px" }}>
          <PointTitle style={{ marginLeft: "0px" }}>
            Coach yourself with SliceGoal
          </PointTitle>
        </div>
      </Intro>

      <LeftGrid>
        <div style={{ gridArea: "left", margin: "auto 0" }}>
          <div
            style={{
              color: "#86E0F8",
              fontSize: "20px",
              margin: "20px",
              textAlign: "left"
            }}
          >
            {"Your coaching, your way"}
          </div>
          <Features>
            <Feature feature={"Get unstuck"} />
            <Feature feature={"Coach yourself"} />
            <Feature feature={"Achieve more goals with less"} />
            <Feature feature={"Use the Wheel of Life!"} />
            {/*<Feature feature={"Achieve goals quicker"} />*/}
            {/*<Feature feature={"Increase clarity and focus"} />*/}
            <Feature feature={"Increase impactful actions"} />
          </Features>
        </div>
        <div
          style={{
            gridArea: "right"
          }}
        >
          <ProductShot />
        </div>
      </LeftGrid>

      {/* <BottomCTA>
        <a target="_blank" href="https://www.slicegoal.com/app/?page=signup&account=coach">
          <Button float={"none"}>Start your free Team Coaching Wheel</Button>
        </a>
      </BottomCTA> */}
      <WheelCarousel tag="innovator" />
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}
      >
        <Link to={"/teams"}>
          <SquareButton float={"none"} margin={10}>
            Teams
          </SquareButton>
        </Link>
        <Link to={"/1on1"}>
          <SquareButton float={"none"} margin={10}>
            1on1
          </SquareButton>
        </Link>
      </div>
    </BodyDiv>
  );
}

export default Self;
