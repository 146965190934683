import React, { useEffect, useState } from "react"
import {
  PointTitle,
  BodyDiv,
  SquareButton
} from "./components"
/* import { Link } from "react-router-dom"; */

import { WheelCarousel } from "./wheel"
import { trackpage } from "./trackpage"
import { Link } from "react-router-dom";

export default function Templates({tagfilter}) {
  useEffect(() => {
    document.title = `Templates`
    trackpage({
      page: window.location.pathname,
      search: window.location.search.substring(1)
    });
  });
  const [filter, settag] = useState(tagfilter);

  return (
    <BodyDiv>
      <title>SliceGoal Templates</title>
      <div style={{textAlign: "center", margin:'auto', marginTop: '60px'}}>
        <PointTitle style={{textAlign: "center"}}>
          Start here.
        </PointTitle>
        <div style={{color: "#9499A3", margin: '10px 20px'}}>
          Choose your area of interest. What areas are you interested in growing?
        </div>
        <div id={'filters'} style={{marginTop:'20px'}}>
          <SquareButton
          onClick={() => settag("")}
          float={"none"}
          //margin={10}
          light={filter !== ""}
          >
            All
          </SquareButton>
          <SquareButton
            onClick={() => settag("business")}
            float={"none"}
            margin={10}
            light={filter !== "business"}
          >
            Business
          </SquareButton>
          <SquareButton
            onClick={() => settag("life")}
            float={"none"}
            //margin={10}
            light={filter !== "life"}
          >
            Lifestyle
          </SquareButton>
        </div>
      </div>
      <WheelCarousel tag={filter} />
      <div style={{gridArea: 'tiles', margin: '0 20px' }}>
          <PointTitle style={{ marginBottom:'10px' }} align={'center'}>
            Not ready to get started yet?
          </PointTitle>
          <div style={{ fontSize: "16px", margin: "20px 0", color: "#9499A3", fontFamily: "sans-serif", textAlign: 'center'}}>
            { "Learn more about SliceGoal first."}
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: "10px", marginBottom: "50px" }}
        >
          <Link to={"/about"}>
            <SquareButton
              float={"none"}
              margin={10}
              light={true}
            >
              About SliceGoal
            </SquareButton>
          </Link>
          <Link to={"/how"}>
          <SquareButton
            float={"none"}
            margin={10}
            light={true}
          >
            How it works
          </SquareButton>
          </Link>
        </div>
    </BodyDiv>
  );
}
