import React, { useEffect } from "react";
import {
  BodyDiv,
  ProductShot,
  PointTitle,
  LeftGrid,
  SquareButton,
  Carousel,
  LeftButton,
  Tiles,
  CVPTitle,
  QuoteSection,
  PromotionCard
} from "./components";

import { Link } from "react-router-dom";
import { trackpage } from "./trackpage";
import { abtest50, abctest33 } from "./ABtesting";

const ablightning = abtest50("lightning", "nolightning");
const abbutton = abctest33(
  {title: "Find out more", link: "about"}, 
  {title: "Take the next step", link: "about"}, 
  {title: "Learn more", link: "about"}
);
const abheader = abctest33(
  {title: "Are you searching for your limitless potential?"}, 
  {title: "Are you passionate about personal growth?"}, 
  {title: "Want superhuman abilities?"}
);
//Sessions Campaign Data 3 values: Set a Goal = 11, Create a wheel = 10, See a wheel = 4
//This should measure the % of click throughs.

function Home() {
  useEffect(() => {
    document.title = `SliceGoal`;
    trackpage({
      page: window.location.pathname,
      search: window.location.search.substring(1),
      abconfig: "button=" + abbutton
    });
  });
  return (
    <BodyDiv style={{ textAlign: "center" }}>
      <title>SliceGoal Coaching Home</title>
      <LeftGrid>
        <CVPTitle style={{gridArea: 'left'}}>
          {ablightning === "lightning" ? (
            <>
              <PointTitle style={{ marginTop: "50px", color: "#414144" }}>
                {abheader.title}
              </PointTitle>
              <div style={{ fontSize: "16px", margin: "20px 0", color: "#9499A3" }}>
                {
                  "Take your life to the next level. Find your flow. Find your beat. Find your revolution."
                }
              </div>
              <LeftButton>
                <a
                  //target="_blank"
                  rel="noreferrer"
                  href={abbutton.link}
                >
                  <SquareButton float={"left"} light={true}>
                    {abbutton.title}
                  </SquareButton>
                </a>
              </LeftButton>
            </>
          ) : (
            <>
              <PointTitle style={{ maxWidth: "600px", marginTop: "50px", color: "#414144" }}>
              {abheader.title}
              </PointTitle>
              <div
                style={{ fontSize: "16px", margin: "20px 0", color: "#9499A3" }}
              >
                {
                  "Take your life to the next level. Find your flow. Find your beat. Find your groove."
                }
              </div>
              <LeftButton>
                <a
                  //target="_blank"
                  rel="noreferrer"
                  href={abbutton.link}
                >
                  <SquareButton float={"left"} light={true}>
                    {abbutton.title}
                  </SquareButton>
                </a>
              </LeftButton>
            </>
          )}
        </CVPTitle>
        <div
          style={{
            gridArea: "right",
            display: 'flex'
          }}
        >
          <ProductShot />
        </div>
      </LeftGrid>
      <Carousel>
        <Tiles>
          {/* <PromotionCard name={'Focus on the right goals at the right time'} text={'You know which goals to focus on for your next step. Connect your purpose to outcomes.'}/>
          <PromotionCard name={'Get unblocked and keep on track'} text={'Nudges to get unblocked. You’ll be back on track before you know it.'}/>
          <PromotionCard name={'Target insights that matter to you most'} text={'Collect insights from people and keep your own insights to help you achieve any goal.'}/> */}
          <PromotionCard name={'Focus on the right things at the right time'} text={'You know what to focus on to connect your purpose to next steps.'} icon={'lightning'}/>
          <PromotionCard name={'Get unblocked and find your path'} text={'Nudges to get unblocked. You’ll be back on track before you know it.'} icon={'lightning'}/>
          <PromotionCard name={'Breakthroughs that have the most impact'} text={'Collect insights to help you achieve any goal and get better at any skill!'} icon={'lightning'}/>
        </Tiles>
      </Carousel>
      <QuoteSection quote={'The single most important relationship in your entire life is the relationship you have with yourself.'} attribution={'Jon Butcher'}/>

      <PointTitle style={{margin:'20px', textAlign: "center", color: '#414144'}}>
        How does it work?
      </PointTitle>

      <div style={{ fontSize: "16px", margin: "20px 0", color: "#9499A3", fontFamily: "sans-serif", textAlign: "center" }}>Click below to find out.</div>
      
      <div style={{ display: "flex", justifyContent: "center", marginTop: "10px", marginBottom: "50px" }}>
        <Link to={"/how"}>
        <SquareButton
          float={"none"}
          margin={10}
        >
          {abbutton.title}
        </SquareButton>
        </Link>
      </div>
    </BodyDiv>
  );
}

export default Home;

